.App {
  text-align: center;
}

.buttons button {
  font-size: 12pt;
}

.buttons {
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
}